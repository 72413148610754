<template>
  <MenuTaskBar>
    <LoadingComponent :load="loading">
      <TitledContainer title="Kunden">
        <table class="c-table">
          <thead>
          <tr>
            <th>#</th>
            <th>Eigentümer</th>
            <th>Firmenname</th>
            <th>E-Mail</th>
            <th>Status</th>
            <th>Lizenzart</th>
            <th>Lizenzen</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(customer, index) in customers">
            <td>{{ index + 1 }}</td>
            <td>{{ customer.owner }}</td>
            <td>{{ customer.company }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ $t('settings.status_' + customer.status) }}</td>
            <td>{{ $t('signup.' + customer.plan_id) }}</td>
            <td>{{ customer.quantity }}</td>
            <td class="icon-container">
              <IconButton
                theme="default"
                icon="images/icons/search--blue.svg"
                @click="$router.push('/admin/customers/' + customer.id)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </TitledContainer>
    </LoadingComponent>
  </MenuTaskBar>
</template>

<script>
  import APIFactory from '../../api/factory'
  import MenuTaskBar from '../../components/admin/menu/MenuTaskbar'
  import IconButton from '../../components/global/buttons/IconButton'
  import TitledContainer from '../../components/admin/TitledContainer'
  import LoadingComponent from '../../components/global/LoadingComponent'
  import moment from 'moment';

  export default {
    data() {
      return {
        customers: [],
        loading: false,
      }
    },
    created() {
      this.loadCustomers()
    },
    methods: {
      loadCustomers() {
        this.loading = true
        APIFactory.findCustomers().then(res => {
          if (res && res.success) {
            this.customers = res.data.sort((t1, t2) => {
              return moment(t2.created_at) > moment(t1.created_at) ? 1 : -1
            })
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      LoadingComponent,
      IconButton,
      MenuTaskBar,
      TitledContainer
    }
  }
</script>

<style lang="scss" scoped>

</style>
